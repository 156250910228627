import React, { useState } from "react";

import close_button from "../../public/nft-details/close_button.svg";

import {
  TwitterShareButton,
  WhatsappShareButton,
  FacebookShareButton,
} from "react-share";

import twitterIcon from "../../public/nft-details/twitter_icon.svg";
import whatsappIcon from "../../public/nft-details/whatsapp_icon.svg";
import facebookIcon from "../../public/nft-details/facebook_icon.svg";
import copyIcon from "../../public/nft-details/copy_icon.svg";
import Image from "next/image";
import { successToast } from "../../utils/toast";

export default function SharePopup({
  showSharePopup,
  setShowSharePopup,
  share_to,
}) {
  const handleClose = () => {
    setShowSharePopup(false);
  };
  // const [showShare, setShowShare] = useState(true);
  if (typeof window !== "undefined") {
    const full_url = document.location.origin + share_to;
    var shareUrl = "";
    if (share_to) {
      shareUrl = full_url;
    } else {
      shareUrl = window.location.href;
    }
  }

  return (
    <>
      <div className={`modal fade ${showSharePopup ? "show block" : ""} `}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="buyNowModalLabel">
                Share
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  handleClose();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>
            <div className="modal-body p-6 flex flex-row  gap-4	items-start">
              <FacebookShareButton url={shareUrl}>
                <Image src={facebookIcon} alt="Facebook Icon" />{" "}
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl}>
                <Image src={twitterIcon} alt="Twitter Icon" />{" "}
              </TwitterShareButton>
              <WhatsappShareButton url={shareUrl}>
                <Image src={whatsappIcon} alt="WhatsApp Icon" />{" "}
              </WhatsappShareButton>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(shareUrl);
                  successToast("Url has been copied");
                }}
                className="flex flex-row  gap-2 cursor-pointer "
              >
                <Image
                  className="align-middle"
                  src={copyIcon}
                  alt="copy Icon"
                />
                <span className="copy-icon-text">Copy Link</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    // <div className="relative ">
    //     <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
    //         <div className="share-popup-title-close">
    //             <h1 className="share-popup-title">Share</h1>
    //             <button className="share-popup-close" onClick={handleClose}>
    //                 <img src={close_button} alt="Close" />
    //             </button>
    //         </div>

    //         <div className="share-popup-icons">
    //             {/*style={{
    //   alignItems: "flex-start",
    //   display: "flex",
    //   justifyContent: "end",
    //   position: "relative",
    //   right: "5px",
    // }}*/}
    //             <TwitterShareButton url={shareUrl}>
    //                 <Image src={twitterIcon} alt="Twitter Icon" />{" "}
    //                 {/* Twitter share button with icon */}
    //             </TwitterShareButton>

    //             <WhatsappShareButton url={shareUrl}>
    //
    //                 {/* WhatsApp share button with icon */}
    //             </WhatsappShareButton>

    //             <FacebookShareButton url={shareUrl}>
    //                 <Image src={facebookIcon} alt="Facebook Icon" />{" "}
    //                 {/* Facebook share button with icon */}
    //             </FacebookShareButton>

    //             <div
    //                 onClick={() => navigator.clipboard.writeText(window.location.href)}
    //                 style={{ cursor: "pointer" }}
    //             >
    //                 <img src={copyIcon} />
    //                 <span className="copy-icon-text">Copy Link</span>
    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
}
