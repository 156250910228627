import { ToastContainer, toast } from 'react-toastify';

export const successToast = (message) => {
  if (message) {
    // toast(message, {
    //   status: 'Successful',
    //   type: 'success',
    //   autoHide: true,
    //   delay: '5000',
    // });
    // showAlert('success','Successful',message);
    toast.success(message, {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }
};

export const errorToast = (message) => {
  if (message) {
    // toast(message, {
    //   status: 'Error',
    //   type: 'error',
    //   autoHide: true,
    //   delay: '5000',
    // });
    // showAlert('error','Error',message);
    toast.error(message, {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }
};
